<template>
  <ion-page>
    <ion-header>
      <MainToolbar :title="i18n.$t('analysis.title')" />
    </ion-header>
    <ion-content>
      <ion-list>
        <div v-for="mainCategory in Object.keys(localizedReportTypeIndex).sort(createOrderSortFunction(localizedReportTypeIndex))" :key="mainCategory">
          <ion-list-header>
            {{ mainCategory }}
          </ion-list-header>

          <ion-item v-for="descriptor in Object.keys(localizedReportTypeIndex[mainCategory].reportTypes).sort(createOrderSortFunction(localizedReportTypeIndex[mainCategory].reportTypes))"
                :key="descriptor"
                @click="router.push('/health/analysis/report/create/' + localizedReportTypeIndex[mainCategory].reportTypes[descriptor].id);"
                button
                detail>
            <ion-label>
              <h3>{{ descriptor }}</h3>
            </ion-label>
          </ion-item>
        </div>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonList, IonListHeader, IonItem, IonLabel } from '@ionic/vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

import MainToolbar from '@/components/MainToolbar.vue';

import { useI18n } from "@/utils/i18n";

import { useRouter } from 'vue-router';

import { isReportTypeVisible } from '@/utils/report';

import _ from 'lodash';

export default  {
  name: 'Analysis',
  components: { IonHeader, IonContent, IonPage, MainToolbar, IonList, IonListHeader, IonItem, IonLabel },
  setup() {
    const i18n = useI18n();

    const store = useStore();

    const router = useRouter();

    const reportTypeIndex = computed(() => {
      let index = store.getters['reports/getReportTypeIndex'];
      
      let newIndex = {};

      for (let [mainCategoryId, reportTypes] of Object.entries(index)){
        for (let [descriptor, reportTypeVersions] of Object.entries(reportTypes)){
          if (isReportTypeVisible(reportTypeVersions.versions[reportTypeVersions.newest_version])){
            if (!(mainCategoryId in newIndex)) { //Only add main categories that have at least one visible report type - Add it once, if visible
              newIndex[mainCategoryId] = { order: 0, reportTypes: {}};
            }
            newIndex[mainCategoryId].reportTypes[descriptor] = reportTypeVersions.versions[reportTypeVersions.newest_version]; //Just add the newest version for this view!
          }
        }
      }

      return newIndex;
    });

    const localizedReportTypeIndex = computed(() => {
      let categories = store.getters['reports/getMainCategories'];

      let mappedReportTypes = _.mapValues(reportTypeIndex.value, function(mainCategory, mainCategoryId) {
        return {
          order: ((mainCategoryId in categories && categories[mainCategoryId].order != null) ? categories[mainCategoryId].order : mainCategory.order),
          reportTypes: _.mapKeys(mainCategory.reportTypes, function(details, descriptor) {
            return descriptor; //TODO Use the translated name here or the descriptor as fallback!
          })
        }
      });

      return _.mapKeys(mappedReportTypes, function(mainCategory, mainCategoryId) {
        if (mainCategoryId in categories && categories[mainCategoryId].name != null && categories[mainCategoryId].name[i18n.locale.value] != null) {
          return categories[mainCategoryId].name[i18n.locale.value];
        }
        return mainCategoryId;
      });
    });

    const createOrderSortFunction = computed(() => function(object) {
      return function(firstKey, secondKey) {
        if (object != null && object[firstKey] != null && object[secondKey] != null && object[firstKey].order != null && object[secondKey].order != null) {
          if (object[firstKey].order < object[secondKey].order) return -1;
          if (object[firstKey].order > object[secondKey].order) return 1;
        }
        return firstKey.localeCompare(secondKey, i18n.locale.value);
      }
    });

    return { i18n, router, reportTypeIndex, localizedReportTypeIndex, createOrderSortFunction };
  }
}
</script>

<style scoped>
ion-list-header {
  font-size: 1.25em;
  font-weight: bold;
  color: var(--ion-color-primary)
}

ion-item ion-label * {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-indent: -35px;
  padding-left: 45px;
}

.analysis-menu{
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
}

.analysis-menu :last-child{
  margin-right: 10px;
}
</style>
